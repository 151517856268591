import { useMicroCopyContext } from "@edenred/micro-copy";
import { Typography } from "@mui/material";

type Props = { cardType?: ERSTypes.CardType };
const CardTypeInfo = ({ cardType }: Props) => {
  const getMicroCopy = useMicroCopyContext();
  if (!cardType) return <></>;

  return (
    <Typography variant="caption">
      {getMicroCopy("retailer.min-max-denomination-info", {
        min: cardType.minAmount,
        max: cardType.maxAmount,
        denomination: cardType.denomination || 1
      })}
    </Typography>
  );
};
export default CardTypeInfo;
