import { useMicroCopyContext } from "@edenred/micro-copy";
import IntegratedButton, { openInNewTabProps } from "../Button";

type Props = { website?: string };
const ViewSiteButton = ({ website }: Props) => {
  const getMicroCopy = useMicroCopyContext();

  if (!website) return <></>;

  return (
    <IntegratedButton variant="outlined" href={website} {...openInNewTabProps}>
      {getMicroCopy("retailer.view-site-button")}
    </IntegratedButton>
  );
};

export default ViewSiteButton;
