import { useMicroCopyContext } from "@edenred/micro-copy";
import { isMobileMediaQuery } from "@edenred/theme";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useMemo, useState } from "react";
import { Data as Retailer } from "../../utils/filterAndMergeRetailers";
import { Context } from "../Provider";
import Image from "../Image";
import { APICardTypes, isCharity } from "../CardType";
import { navigate } from "gatsby";
import { PageWithHeaderAndFooter } from "../Page";
import BackButton from "../BackButton";
import Carousel from "@edenred/carousel";
import {
  Alert,
  AlertTitle,
  FormControl,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import CompetitionIcon from "@mui/icons-material/EmojiEventsOutlined";
import Button from "@edenred/button";
import Container from "@edenred/container";
import ViewSiteButton from "./ViewSiteButton";
import RetailerCard from "./RetailerCard";
import RetailerRewardDetails from "../RetailerRewardDetails";
import HowToRedeem from "./HowToRedeem";
import RetailerValue from "../RetailerValue";
import CardTypeInfo from "./CardTypeInfo";
import RetailerCardTypes from "./RetailerCardTypes";

type RetailerPageProps = {
  retailer: Retailer;
  homePath: string;
  editCardTypeId?: number;
  client: GatsbyTypes.ContentfulClient;
};
function RetailerPageView({
  homePath,
  retailer,
  client,
  editCardTypeId
}: RetailerPageProps) {
  const getMicroCopy = useMicroCopyContext();
  const isMobile = isMobileMediaQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { findInBasket, addBasketItem, updateBasketItem } = useContext(Context);
  const [valueError, setValueError] = useState<string | undefined>();

  useEffect(() => {
    setSelectedCardType(undefined);
  }, [retailer, editCardTypeId]);

  const initialCardType = useMemo(
    () =>
      retailer.cardTypes.find(
        (c) => c.id === (editCardTypeId === undefined ? c.id : editCardTypeId)
      ) || retailer.cardTypes[0],
    [retailer, editCardTypeId]
  );

  const [selectedCardType, setSelectedCardType] = useState<
    ERSTypes.CardType | undefined
  >();

  const activeCardType = useMemo(
    () => (selectedCardType === undefined ? initialCardType : selectedCardType),
    [selectedCardType, initialCardType]
  );

  const basketItem = useMemo(
    () => findInBasket(retailer.id, activeCardType.id),
    [retailer, activeCardType]
  );

  const [value, setValue] = useState<number | null>(
    basketItem && editCardTypeId ? basketItem.value : null
  );

  const carouselImages = useMemo(
    () =>
      retailer.images
        ?.filter((i) => i !== undefined)
        .map((image, index) => <Image key={index} data={image!} />) || [],
    [retailer]
  );

  const shouldShowHowToRedeemSection = useMemo(
    () =>
      editCardTypeId !== APICardTypes.CHARITY &&
      retailer.cardTypes.some((cardType) => !isCharity(cardType)) &&
      (retailer.redeemInStore || retailer.redeemOnline),
    [retailer, editCardTypeId]
  );

  const disableFormButton = useMemo(
    () => !value || !activeCardType || !!valueError,
    [value, activeCardType, valueError]
  );

  const addToBasket = (basketItem: ERSTypes.Basket) => {
    addBasketItem(basketItem);
    enqueueSnackbar(getMicroCopy("basket.added"), { variant: "success" });
    setValue(null);
  };

  const updateBasket = (
    basketItem: ERSTypes.Basket,
    editCardTypeId: number
  ) => {
    updateBasketItem(basketItem, editCardTypeId);
    enqueueSnackbar(getMicroCopy("basket.updated"), { variant: "success" });
    navigate(`${homePath}/basket`);
  };

  const handleAddToBasket = (basketItem: ERSTypes.Basket) => {
    if (!basketItem.retailer) {
      enqueueSnackbar(getMicroCopy("basket.error"), {
        variant: "error"
      });
      return;
    }
    editCardTypeId !== undefined
      ? updateBasket(basketItem, editCardTypeId)
      : addToBasket(basketItem);
  };

  return (
    <PageWithHeaderAndFooter
      title={`Retailer - ${retailer.title}`}
      description={`Redeem a voucher for ${retailer.title}.`}
      client={client}
      homePath={homePath}
    >
      <BackButton />
      {isMobile && carouselImages && (
        <Carousel
          aspectRatio="1.5"
          dotLabel={(index) => getMicroCopy("carousel.dot-label", { index })}
        >
          {carouselImages}
        </Carousel>
      )}
      <Container sxStyle={{ pt: { sm: 4.5 } }}>
        <Grid container spacing={3}>
          {!isMobile && (
            <Grid item xs={12} sm={6}>
              <Carousel
                aspectRatio="1"
                dotLabel={(index) =>
                  getMicroCopy("carousel.dot-label", { index })
                }
                sxStyle={{ borderRadius: 1 }}
              >
                {carouselImages}
              </Carousel>
              <ViewSiteButton website={retailer.website} />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Stack
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <RetailerCard title={retailer.title} logo={retailer.logo} />
              {isMobile && <ViewSiteButton website={retailer.website} />}
            </Stack>
            {retailer?.competition && (
              <Alert
                severity="info"
                icon={<CompetitionIcon color="primary" />}
                sx={{ mt: 1.5 }}
              >
                <AlertTitle>{retailer?.competition?.title}</AlertTitle>
                <Typography variant="body2">
                  {retailer?.competition?.description}
                </Typography>
              </Alert>
            )}
            <FormControl sx={{ display: "block", my: { xs: 4.5, sm: 6 } }}>
              <Typography
                id="card-type-button-group"
                variant="subtitle2"
                component="label"
                sx={{ display: "block", mb: 1.5 }}
              >
                {getMicroCopy("retailer.card-type")}
              </Typography>
              <RetailerCardTypes
                cardTypes={retailer.cardTypes}
                activeCardTypeId={activeCardType?.id}
                onSelect={(cardType) => {
                  setSelectedCardType(cardType);
                }}
              />
            </FormControl>
            <FormControl sx={{ display: "block", my: { xs: 4.5, sm: 6 } }}>
              <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                <Typography
                  id="value-button-group"
                  variant="subtitle2"
                  component="label"
                  sx={{ display: "block" }}
                >
                  {getMicroCopy("retailer.value")}
                </Typography>
                <CardTypeInfo cardType={activeCardType} />
              </Stack>
              <RetailerValue
                value={value}
                onValueChange={setValue}
                onValueError={setValueError}
                allowDecimals={client.allowDecimals}
                retailerId={retailer.id}
                activeCardType={activeCardType}
                editCardTypeId={editCardTypeId}
              />
            </FormControl>
            {shouldShowHowToRedeemSection && (
              <HowToRedeem
                inStore={retailer.redeemInStore}
                online={retailer.redeemOnline}
              />
            )}
            <Button
              disabled={disableFormButton}
              fullWidth
              onClick={() =>
                retailer &&
                activeCardType &&
                handleAddToBasket({
                  retailer,
                  cardType: activeCardType,
                  value: value || 0
                })
              }
              size="large"
            >
              {editCardTypeId !== undefined
                ? getMicroCopy("retailer.update-basket")
                : getMicroCopy("retailer.add-to-basket")}
            </Button>
            <RetailerRewardDetails retailer={retailer} />
          </Grid>
        </Grid>
      </Container>
    </PageWithHeaderAndFooter>
  );
}

export default RetailerPageView;
