import { Stack, Typography } from "@mui/material";
import RetailerAvatar from "../RetailerAvatar";

type Props = { logo?: GatsbyTypes.ContentfulImage; title?: string };
const RetailerCard = ({ logo, title }: Props) => {
  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <RetailerAvatar
        title={title}
        logo={logo}
        alt={`${title} logo`}
        sxStyle={{
          width: {
            xs: 48,
            sm: 72
          },
          height: {
            xs: 48,
            sm: 72
          }
        }}
      />
      <Typography variant="h4" component="h1">
        {title}
      </Typography>
    </Stack>
  );
};
export default RetailerCard;
