import { Box, Stack, Typography } from "@mui/material";
import Accordion from "@edenred/accordion";
import { useMicroCopyContext } from "@edenred/micro-copy";
import RichText from "./RichText";
import { CardTypeAvatar, CardTypeIcon } from "./CardType";
import { Data as Retailer } from "../utils/filterAndMergeRetailers";

type Props = { retailer: Retailer };

const RetailerRewardDetails = ({ retailer }: Props) => {
  const getMicroCopy = useMicroCopyContext();
  return (
    <Box sx={{ mt: 6 }}>
      <Accordion>
        <Accordion.Summary
          aria-controls="description-content"
          id="description-header"
        >
          <Typography>{getMicroCopy("retailer.description")}</Typography>
        </Accordion.Summary>
        <Accordion.Details>
          <RichText document={retailer.description} typographyVariant="body2" />
        </Accordion.Details>
      </Accordion>
      <Accordion>
        <Accordion.Summary
          aria-controls="reward-details-content"
          id="reward-details-header"
        >
          <Typography>
            {getMicroCopy("retailer.reward-details-heading")}
          </Typography>
        </Accordion.Summary>
        <Accordion.Details>
          <Typography variant="body2">
            {getMicroCopy("retailer.reward-details-intro")}
          </Typography>
          {retailer.cardTypes.map((cardType) => (
            <Box key={cardType.id} sx={{ mt: 3 }}>
              <Stack
                direction="row"
                sx={{ alignItems: "center" }}
                spacing={1.5}
              >
                <CardTypeAvatar>
                  <CardTypeIcon data={cardType} color="primary" />
                </CardTypeAvatar>
                <Typography variant="body2">{cardType.name}</Typography>
              </Stack>
              <Typography sx={{ mt: 1, ml: 6 }} variant="body2">
                {getMicroCopy(`card-type.${cardType.id}.description`)}
              </Typography>
            </Box>
          ))}
        </Accordion.Details>
      </Accordion>
      <Accordion>
        <Accordion.Summary
          aria-controls="terms-and-conditions-content"
          id="terms-and-conditions-header"
        >
          <Typography>{retailer.terms?.title}</Typography>
        </Accordion.Summary>
        <Accordion.Details>
          <RichText
            document={retailer.terms?.content}
            typographyVariant="body2"
          />
        </Accordion.Details>
      </Accordion>
      {retailer.competition && (
        <Accordion>
          <Accordion.Summary
            aria-controls="competitions-content"
            id="competitions-header"
          >
            <Typography>
              {getMicroCopy("retailer.competitions-header")}
            </Typography>
          </Accordion.Summary>
          <Accordion.Details>
            <RichText
              document={retailer.competition.terms}
              typographyVariant="body2"
            />
          </Accordion.Details>
        </Accordion>
      )}
    </Box>
  );
};

export default RetailerRewardDetails;
