import { useMicroCopyContext } from "@edenred/micro-copy";
import { Button, Typography } from "@mui/material";
import { useToken } from "../../hooks/useToken";
import ErrorPage from "../ErrorPage";

type Props = { homePath: string; client?: GatsbyTypes.ContentfulClient };
const RetailerErrorPage = ({ homePath, client }: Props) => {
  const getMicroCopy = useMicroCopyContext();
  const [token] = useToken();

  return (
    <ErrorPage
      homePath={homePath}
      title={getMicroCopy("error.not-found.title")}
      description={getMicroCopy("error.not-found.description")}
      client={client}
    >
      <Typography variant="h4" component="h1" sx={{ margin: 3 }}>
        {getMicroCopy("error.not-found.title")}
      </Typography>
      <Button href={token ? `${homePath}/basket` : `${homePath}/`} size="large">
        {getMicroCopy("general.back")}
      </Button>
    </ErrorPage>
  );
};

export default RetailerErrorPage;
