import { useMicroCopyContext } from "@edenred/micro-copy";
import {
  Collapse,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import {
  calculateMidValue,
  roundDownToDenomination
} from "../utils/calculateMidValue";
import { convertToNumber } from "../utils/convert";
import { roundMoney } from "../utils/money";
import { Context } from "./Provider";
import RetailerAmountRadio, { RadioBlockLabel } from "./RetailerAmountRadio";

type Props = {
  value: number | null;
  retailerId: string;
  editCardTypeId?: number;
  allowDecimals?: boolean;
  activeCardType: ERSTypes.CardType;
  onValueError: (error?: string) => void;
  onValueChange: (value: number | null) => void;
};

const RetailerValue = ({
  allowDecimals,
  retailerId,
  editCardTypeId,
  activeCardType,
  value,
  onValueError,
  onValueChange
}: Props) => {
  const theme = useTheme();
  const getMicroCopy = useMicroCopyContext();
  const { balance, basket, findInBasket } = useContext(Context);
  const [showCustomField, setShowCustomField] = useState<boolean>(false);
  const [availableValues, setAvailableValues] = useState<number[]>([]);

  const basketItem = useMemo(
    () => findInBasket(retailerId, activeCardType.id),
    [retailerId, activeCardType]
  );
  const originalValue = useMemo(
    () => (basketItem && editCardTypeId ? basketItem.value : 0),
    []
  );

  const availableBalance = useMemo(
    () => (editCardTypeId ? balance + originalValue : balance),
    [editCardTypeId, originalValue, balance]
  );

  const remainingBalance = useMemo(() => {
    return value === null ? availableBalance : availableBalance - value;
  }, [availableBalance, value]);

  const sumOfItems = useMemo(() => {
    const basketItems = basket.filter((i) => i.retailer.id === retailerId);
    return basketItems && basketItems.length > 0
      ? basketItems.reduce((prev, curr) => prev + curr.value, 0) - originalValue
      : 0;
  }, [basket, retailerId, originalValue]);

  const hasMaxAmountReached = useMemo(() => {
    const { maxAmount } = activeCardType;
    return sumOfItems >= maxAmount;
  }, [sumOfItems, activeCardType]);

  const validationError: string | undefined = useMemo(() => {
    const { minAmount, maxAmount, denomination = 1 } = activeCardType;
    let validationError: string | undefined;
    const _value = value || 0;
    if (hasMaxAmountReached) {
      validationError = getMicroCopy(
        "retailer.custom.reached-max-amount-error",
        {
          maxAmount: String(maxAmount)
        }
      );
    } else if (remainingBalance < 0) {
      validationError = getMicroCopy("retailer.custom.amount-error");
    } else if (_value < minAmount) {
      validationError = getMicroCopy("retailer.custom.min-amount-error");
    } else if (_value + sumOfItems > maxAmount) {
      validationError = getMicroCopy("retailer.custom.max-amount-error");
    } else if (_value % denomination !== 0) {
      validationError = getMicroCopy("retailer.custom.denomination-error", {
        denomination: String(denomination)
      });
    }

    onValueError(validationError);
    return validationError;
  }, [value, remainingBalance, activeCardType, hasMaxAmountReached]);

  useEffect(() => {
    let availableValues: number[] = [];
    if (
      (editCardTypeId !== undefined || !hasMaxAmountReached) &&
      activeCardType.minAmount <= availableBalance
    ) {
      const { minAmount, maxAmount, denomination } = activeCardType;
      const actualMaxAmount = maxAmount - sumOfItems;
      const maxValue = roundDownToDenomination(
        actualMaxAmount > availableBalance ? availableBalance : actualMaxAmount,
        denomination
      );
      const middleValue = calculateMidValue(minAmount, maxValue, denomination);
      availableValues = [...new Set([minAmount, middleValue, maxValue])];
    }
    setAvailableValues(availableValues);
    setShowCustomField(
      value === null ? false : !availableValues.includes(value)
    );
  }, [
    activeCardType,
    availableBalance,
    sumOfItems,
    hasMaxAmountReached,
    editCardTypeId
  ]);

  const handleCustomValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = convertToNumber(target.value, allowDecimals);
    onValueChange(value);
  };

  return (
    <>
      <RadioGroup
        aria-labelledby="value-button-group"
        name="value-button-group"
        value={value}
        sx={{ mb: 2, gap: 1 }}
        row
      >
        {availableValues.length > 0 ? (
          <>
            {availableValues.map((amount) => (
              <RetailerAmountRadio
                key={`amount-${amount}`}
                amount={amount}
                onClick={() => {
                  setShowCustomField(false);
                  onValueChange(amount);
                }}
                variant={
                  value === amount && !showCustomField
                    ? "contained"
                    : "outlined"
                }
                disabled={amount > balance + originalValue}
              />
            ))}
            <FormControlLabel
              control={<Radio sx={{ display: "none" }} />}
              sx={{ ml: 0 }}
              label={
                <RadioBlockLabel
                  onClick={() => {
                    if (!showCustomField) {
                      setShowCustomField(true);
                    }
                  }}
                  variant={showCustomField ? "contained" : "outlined"}
                >
                  <Typography>{getMicroCopy("retailer.custom")}</Typography>
                </RadioBlockLabel>
              }
            />
          </>
        ) : (
          <Stack direction="column" justifyContent="center">
            <RetailerAmountRadio amount={0} disabled={true} />
            {hasMaxAmountReached && balance > 0 && (
              <Typography variant="caption" color={theme.palette.error.main}>
                {getMicroCopy("retailer.custom.reached-max-amount-error", {
                  maxAmount: String(activeCardType?.maxAmount ?? "")
                })}
              </Typography>
            )}
          </Stack>
        )}
      </RadioGroup>
      <Collapse in={showCustomField}>
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "d*" }}
          autoComplete="off"
          label={getMicroCopy("retailer.enter-code")}
          name="custom"
          onChange={handleCustomValueChange}
          helperText={value !== null ? validationError : undefined}
          error={value !== null && !!validationError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getMicroCopy("general.currency", { value: "" })}
              </InputAdornment>
            )
          }}
          sx={{ mb: 2 }}
          value={value}
        />
      </Collapse>
      <Typography variant="caption">
        {getMicroCopy("retailer.remaining-balance", {
          value: remainingBalance < 0 ? 0 : roundMoney(remainingBalance)
        })}
      </Typography>
    </>
  );
};

export default RetailerValue;
