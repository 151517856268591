import { Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { Typography, Link } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { ReactNode } from "react";
import { openInNewTabProps } from "./Button";

type RichTextProps = {
  document?: GatsbyTypes.ContentfulRichText;
  typographyVariant?: Variant;
};

const options = (typographyVariant?: Variant): Options => {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => {
        if (
          Array.isArray(children) &&
          (!children.length || (children.length === 1 && !children[0]))
        ) {
          return null;
        }

        return <Typography variant={typographyVariant}>{children}</Typography>;
      },
      [BLOCKS.HEADING_2]: (_node, children) => (
        <Typography variant="h2">{children}</Typography>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Typography variant="h3">{children}</Typography>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <Typography variant="h4">{children}</Typography>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <Typography variant="h5">{children}</Typography>
      ),
      [BLOCKS.HEADING_6]: (_node, children) => (
        <Typography variant="h6">{children}</Typography>
      ),
      [INLINES.HYPERLINK]: (_node, children: ReactNode) => (
        <Link href={_node.data.uri} {...openInNewTabProps} color="inherit">
          {children}
        </Link>
      )
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <i>{text}</i>,
      [MARKS.UNDERLINE]: (text) => (
        <span style={{ textDecoration: "underline" }}>{text}</span>
      )
    }
  };
};

const RichText = ({ document, typographyVariant }: RichTextProps) => {
  if (!document) {
    return null;
  }
  const richTextOptions = options(typographyVariant);
  // @ts-expect-error The generated types differ in what props are required to
  // what the function is expecting here.
  return <>{renderRichText(document, richTextOptions)}</>;
};

export default RichText;

export const query = graphql`
  fragment RichText on ContentfulRichText {
    raw
  }
`;
