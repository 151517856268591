import { StyledProps } from "@edenred/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  SxProps
} from "@mui/material";

const style = {
  "&:before": {
    display: "none"
  },
  "&:not(:last-child)": {
    borderBottom: "none"
  }
} as const;

const sxAccordionSummary: SxProps = {
  alignItems: "center",
  ".MuiAccordionSummary-content": {
    my: 2.75
  }
};

const Accordion = ({ sxStyle, ...props }: StyledProps<AccordionProps>) => {
  return (
    <MuiAccordion
      variant="outlined"
      disableGutters
      {...props}
      sx={{ ...style, ...sxStyle }}
    >
      {props.children}
    </MuiAccordion>
  );
};

const AccordionSummary = (props: AccordionSummaryProps) => {
  return (
    <MuiAccordionSummary
      sx={sxAccordionSummary}
      expandIcon={<ExpandMoreIcon />}
      {...props}
    >
      {props.children}
    </MuiAccordionSummary>
  );
};

const AccordionDetails = (props: AccordionDetailsProps) => {
  return <MuiAccordionDetails {...props}>{props.children}</MuiAccordionDetails>;
};

Accordion.Summary = AccordionSummary;
Accordion.Details = AccordionDetails;

export default Accordion;
