import { useMicroCopyContext } from "@edenred/micro-copy";
import { Box, Stack, Typography } from "@mui/material";
import { CardTypeRedemption } from "../CardType";

type Props = { inStore?: boolean; online?: boolean };
const HowToRedeem = ({ inStore, online }: Props) => {
  const getMicroCopy = useMicroCopyContext();
  return (
    <Box sx={{ my: { xs: 4.5, sm: 6 } }}>
      <Typography variant="subtitle2" component="p" sx={{ mb: 2 }}>
        {getMicroCopy("retailer.how-to-redeem")}
      </Typography>
      <Stack direction="row" spacing={2}>
        <CardTypeRedemption redeemInStore={inStore} redeemOnline={online} />
      </Stack>
    </Box>
  );
};

export default HowToRedeem;
