import { graphql, PageProps } from "gatsby";
import { useContext, useMemo } from "react";
import { withProtected } from "../components/Protected";
import { Context } from "../components/Provider";
import RedirectToLowerCaseUrl from "../components/RedirectToLowerCaseUrl";
import RetailerErrorPage from "../components/Retailer/ErrorPage";
import RetailerPageView from "../components/Retailer/RetailerPageView";

type Props = PageProps<
  Pick<GatsbyTypes.Query, "contentfulRetailer" | "contentfulClient">,
  ERSTypes.PageContext
>;

function RetailerPage({ params, ...rest }: Props) {
  const { retailerLookup, findInBasket } = useContext(Context);
  const {
    pageContext: { homePath },
    data: { contentfulClient: client }
  } = rest;

  const retailer = useMemo(
    () =>
      Object.values(retailerLookup).find(
        ({ slug }) => slug?.toLowerCase() === params.retailer?.toLowerCase()
      ),
    [retailerLookup, params]
  );

  const editCardTypeId = useMemo(
    () =>
      params.isEditingCardTypeID
        ? parseInt(params.isEditingCardTypeID)
        : undefined,
    [params]
  );

  const validCardTypes = useMemo(
    () =>
      !!retailer &&
      (editCardTypeId !== undefined
        ? retailer.cardTypes.some((c) => c.id === editCardTypeId) &&
          findInBasket(retailer.id, editCardTypeId)
        : retailer.cardTypes.length > 0),
    [editCardTypeId, retailer]
  );

  return (
    <RedirectToLowerCaseUrl>
      {retailer && validCardTypes && client ? (
        <RetailerPageView
          homePath={homePath}
          client={client}
          editCardTypeId={editCardTypeId}
          retailer={retailer}
        />
      ) : (
        <RetailerErrorPage homePath={homePath} client={client} />
      )}
    </RedirectToLowerCaseUrl>
  );
}

export default withProtected(RetailerPage);

export const pageQuery = graphql`
  query RetailerPageQuery($id: String!) {
    contentfulClient(id: { eq: $id }) {
      ...Client
    }
  }
`;
