import { useLocation } from "@reach/router";
import Redirect from "./Redirect";

type Props = { children?: JSX.Element };

const RedirectToLowerCaseUrl = ({ children }: Props) => {
  const { pathname, search } = useLocation();
  if (/[A-Z]/.test(pathname))
    return <Redirect pathname={pathname.toLowerCase()} search={search} />;

  return <>{children}</>;
};

export default RedirectToLowerCaseUrl;
