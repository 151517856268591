function calculateMidValue(
  minValue: number,
  maxValue: number,
  denomination?: number
) {
  denomination = denomination || 5;
  if (denomination === maxValue) return denomination;
  if (denomination >= maxValue) return 0;
  if (denomination < 1) return 0;
  if (maxValue <= minValue) return minValue;

  const mid = (maxValue + minValue) / 2;
  const residualNumber = mid % denomination;
  const divisibleMid = mid - residualNumber;
  if (divisibleMid < minValue) return 0;
  if (divisibleMid <= 0) return denomination;
  const howMany = divisibleMid / denomination;
  const multiplier =
    divisibleMid % 5 === 0 ? 0 : howMany < 5 ? 5 - howMany : (howMany % 5) * -1;
  let foundMid = divisibleMid + multiplier * denomination;
  if (foundMid <= minValue || foundMid >= maxValue) foundMid = divisibleMid;
  const lastResult = Math.round(mid / foundMid) * foundMid;

  return lastResult === 0 ? divisibleMid : lastResult;
}

function roundDownToDenomination(value: number, denomination?: number) {
  if (denomination && value % denomination !== 0) {
    return Math.round(value / denomination) * denomination;
  } else {
    return value;
  }
}

export { calculateMidValue, roundDownToDenomination };
