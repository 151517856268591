import { Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Container from "@edenred/container";
import IntegratedButton from "./Button";
import { navigate } from "gatsby";
import { useContext } from "react";
import { MicroCopyContext } from "@edenred/micro-copy";

export default function BackButton() {
  const getMicroCopy = useContext(MicroCopyContext);
  return (
    <Box
      sx={{
        height: 48,
        borderBottom: 1,
        borderBottomColor: "grey.300",
        display: "flex",
        alignItems: "center"
      }}
    >
      <Container>
        <IntegratedButton
          onClick={() => navigate(-1)}
          sx={{
            ml: -1,
            "&:hover": {
              bgcolor: "transparent"
            }
          }}
          variant="text"
        >
          <ChevronLeftIcon />
          {getMicroCopy("general.back")}
        </IntegratedButton>
      </Container>
    </Box>
  );
}
