import { decimal, integer } from "./regex";

export const convertToNumber = (
  value: string = "",
  allowDecimals?: boolean
): number | null => {
  if (!value) return null;
  const numberRegex = allowDecimals ? decimal : integer;
  const valueMatch = value.match(numberRegex);
  if (!valueMatch) return null;
  else return Number(valueMatch[0]);
};
