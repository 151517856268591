import { useMicroCopyContext } from "@edenred/micro-copy";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { CardTypeDetails, CardTypeIcon, isCharity } from "../CardType";
import { RadioBlockLabel } from "../RetailerAmountRadio";

type Props = {
  cardTypes: ERSTypes.CardType[];
  activeCardTypeId?: number;
  onSelect: (cardType: ERSTypes.CardType) => void;
};

const RetailerCardTypes = ({
  cardTypes,
  activeCardTypeId,
  onSelect
}: Props) => {
  const getMicroCopy = useMicroCopyContext();

  return (
    <RadioGroup
      aria-labelledby="card-type-button-group"
      name="card-type-button-group"
      sx={{ gap: 1 }}
      row
    >
      {cardTypes.length === 1 ? (
        <CardTypeDetails data={cardTypes[0]} />
      ) : (
        cardTypes.map((cardType) => (
          <FormControlLabel
            key={`card-type-${cardType.id}`}
            value={cardType.id}
            control={<Radio sx={{ display: "none" }} />}
            sx={{
              margin: 0,
              flexGrow: 1,
              ".MuiFormControlLabel-label": { width: "100%" }
            }}
            label={
              <RadioBlockLabel
                caption={
                  isCharity(cardType)
                    ? undefined
                    : getMicroCopy(`card-type.${cardType.id}.postage`)
                }
                onClick={() => onSelect(cardType)}
                variant={
                  activeCardTypeId === cardType.id ? "contained" : "outlined"
                }
                sx={{
                  borderRadius: 1,
                  justifyContent: "space-between"
                }}
                fullWidth
              >
                <CardTypeIcon data={cardType} />
                <Typography>{cardType.name}</Typography>
              </RadioBlockLabel>
            }
          />
        ))
      )}
    </RadioGroup>
  );
};

export default RetailerCardTypes;
