import {
  FormControlLabel,
  Radio,
  Stack,
  SxProps,
  Typography
} from "@mui/material";
import Button, { ButtonProps } from "@edenred/button";
import { useContext } from "react";
import { MicroCopyContext } from "@edenred/micro-copy";

type Props = {
  amount?: number;
  onClick?: () => void;
  variant?: "contained" | "outlined";
  disabled?: boolean;
};

export const RadioBlockLabel = ({
  caption,
  children,
  sx,
  ...rest
}: ButtonProps & { caption?: string }) => {
  const style: SxProps = {
    borderColor: "primary.main",
    borderStyle: "solid",
    borderWidth: 1.5,
    boxShadow: 0,
    boxSizing: "borderBox",
    display: "flex",
    margin: 0,
    p: 1.5
  };

  return (
    <Button sx={{ ...style, ...sx }} {...rest}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {children}
      </Stack>
      {caption && <Typography variant="body2">{caption}</Typography>}
    </Button>
  );
};
const RetailerAmountRadio = ({
  amount = 0,
  onClick = () => {},
  variant = "outlined",
  disabled = false
}: Props) => {
  const getMicroCopy = useContext(MicroCopyContext);
  return (
    <FormControlLabel
      key={`amount-${amount}`}
      value={amount}
      control={<Radio sx={{ display: "none" }} />}
      sx={{ mx: 0 }}
      label={
        <RadioBlockLabel
          onClick={onClick}
          variant={variant}
          disabled={disabled}
        >
          <Typography>
            {getMicroCopy("general.currency", {
              value: amount
            })}
          </Typography>
        </RadioBlockLabel>
      }
    />
  );
};

export default RetailerAmountRadio;
